import React from "react";
import Avvvatars from "avvvatars-react";
import { nanoid } from "nanoid";

export default function Header(props) {

    return (
        <div className="header stick" id="myHeader">
            <h1 onClick={() => props.sectionHandler(false)}>Артём Александров</h1>
            <div className="menu">
                <div className="menu-item" onClick={() => props.sectionHandler('CS')}>
                    <Avvvatars style="shape" value={props.cs} size="25" />
                    <span className="menu-text">Программирование</span>
                </div>
                <div className="menu-item" onClick={() => props.sectionHandler('Engineering')}>
                    <Avvvatars style={"shape"} value={props.engineering} size="25" />
                    <span className="menu-text">Проектирование</span>
                </div>
                <div className="menu-item" onClick={() => props.sectionHandler(false)}>
                    <Avvvatars style={"shape"} value={nanoid()} size="25" />
                    <span className="menu-text">всё</span>
                </div>
                <div className="menu-item" onClick={() => props.sectionHandler('CV')}>
                    <Avvvatars style={"shape"} value={props.cv} size="25" />
                    <span className="menu-text">резюме/CV</span>
                </div>
            </div>
            <hr></hr>
        </div>
    )
}