//function to fetch API data
export default async function getData(setPostData, postData) {
    try {
        console.log('Fetching');
        const url = 'https://artemalexandrov.ru/php/php-rest/api/read.php';
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed. Server response ${response.status}`)
        }
        const json = await response.json();


        if (json.response_code === 1) {
            throw new Error(`Failed. Wrong API request. No results`)
        } else if (json.response_code === 2) {
            throw new Error(`Failed. Wrong API parameters. No results`)
        }
        console.log('Fetched!')

        setPostData(
            {
                isLoaded: true,
                isClicked: false,
                posts: json
            }
        )
        
    }
    catch (err) {
        console.error(err);
        setPostData({
            isLoaded: false,
            isClicked: false,
            errorMessage: 'Somethin went wrong. Please Try Again!',
            posts: null
        });
    }

}

