import React from 'react';
import './App.css';
import './gallery.scss';
import { Routes, Route } from "react-router-dom";
import AdminPanel from './AdminPanel';
import GeneralContent from './GeneralContent';

function NoMatch() {
  return (
    <div className="404">Тут нет такого!</div>
  )
}

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<GeneralContent />} />
      <Route path='admin' element={<AdminPanel />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>

  );
}