import React from 'react';
import './App.css';
import Header from './Header';
import Post from "./Post.js"
import getData from './getData';
import { nanoid } from "nanoid";
import { useLocation } from "react-router-dom";
// import dataRu from './dataRu';

export default function GeneralContent() {

  //generate unique strings for avvvatar pics
  const cs = nanoid();
  const engineering = nanoid();
  const cv = nanoid();

  //preloader animation
  const preloader = <div className="loader-container"><div className="spinner"></div></div>

  //posts data state
  const [postData, setPostData] = React.useState({
    isLoaded: false,
    isClicked: false,
    posts: null,
  })


  //loading posts data and updating data state

  //hardcoded version of useEffect
  // React.useEffect(() => {
  //         setPostData(
  //       {
  //         isLoaded: true,
  //         isClicked: false,
  //         posts: dataRu
  //       }
  //     )
    
    
  // }, []);

  React.useEffect(() => {
    getData(setPostData);
  }, []);


  //the preloading value for posts content
  let content = preloader;

  //if id is present in the URL
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  if (postData.isLoaded && id) {
    console.log('post id found = ', id);
    content = [];
    for (let i = 0; i < postData.posts.length; i++) {
      if (postData.posts[i].id == id) {
        let avvvatarKey = '';
        switch (postData.posts[i].section) {
          case 'CS': { avvvatarKey = cs; break }
          case 'Engineering': { avvvatarKey = engineering; break }
          case 'CV': { avvvatarKey = cv; break }
          default: { avvvatarKey = 'default' }
        }
        content = <Post key={postData.posts[i].id} title={postData.posts[i].title} text={postData.posts[i].innertext} tags={postData.posts[i].tags} avvvatar={avvvatarKey} />;
      }
    }
    
    if (content.length < 1) {
      content = 'Нет постов с id = ' + id;
    }
  }

  //generating components to show
  if (postData.isLoaded && !id) {
    content = postData.posts.map(item => {

      //making unique avvvatar for each section in posts. 
      //These avvvatars will be the same as in the Menu
      //love that wierd switch compose
      let avvvatarKey = '';
      switch (item.section) {
        case 'CS': { avvvatarKey = cs; break }
        case 'Engineering': { avvvatarKey = engineering; break }
        case 'CV': { avvvatarKey = cv; break }
        default: { avvvatarKey = 'default' }
      }


      //checking case when some section was chosen and returning if post is a not from chosen section    

      if (postData.isClicked) {
        const theSection = item.section.split(',');
        if (Array.isArray(theSection)) {
          for (let i = 0; i < theSection.length; i++) {
            if (postData.isClicked === theSection[i]) {
              return (
                <Post key={item.id} title={item.title} text={item.innertext} tags={item.tags} avvvatar={avvvatarKey} />
              )
            }
          };
          return null;
        }
      }


      //returning bunch of the posts

      return (
        <Post key={item.id} title={item.title} text={item.innertext} tags={item.tags} avvvatar={avvvatarKey} />
      )
    })

  };


  //update state with tag that some section was choosen
  function sectionHandler(section) {

    setPostData(oldData => {
      return ({
        ...oldData,
        isClicked: section
      })


    })
  }

  return (
    <div className="App">
      <Header cs={cs} engineering={engineering} cv={cv} sectionHandler={sectionHandler} />
      <div className="content">
        {content}
      </div>
    </div>
  );
}